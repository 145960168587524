import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';
import Badge from '../ui/Badge';
import { IconContainer } from '../Layout/Header/Header';
import { EztradDesktopCartButtonStyling } from '../Layout/Header/EztradStyling';
import useShopId from '../../utils/useShopId';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  text-transform: lowercase;
  justify-content: center;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  ${({ theme }) => theme.above.md} {
    border-bottom-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.green : 'transparent'};

    :hover {
      border-bottom-color: ${({ theme }) => theme.colors.green};
    }
  }

  svg {
    height: 17px;
    width: 17px;
    margin-left: 9px;
  }

  .cart-button-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    font-size: 0.9375rem;
    font-variant: small-caps;
    color: inherit;
    letter-spacing: 0.05em;
    font-weight: 500;
  }

  .badge {
    right: -8px;
    top: -4px;
    position: absolute;
  }

  ${({ theme }) => theme.below.lg} {
    .cart-button-inner {
      flex-direction: column;
    }

    svg {
      margin: 0px;
    }

    .badge {
      top: 2px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${({ isEztrad }) => isEztrad && EztradDesktopCartButtonStyling};
`;

const CartLabel = styled('label')`
  font-size: 0.625rem;
  font-variant: small-caps;
  color: ${({ theme }) => theme.colors.green};
  letter-spacing: 0.05em;
  font-weight: 500;
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }
`;

function CartButton() {
  const isEztrad = useShopId() === 'eztrad';
  return (
    <Fragment>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

          return (
            // Use a flyout for breakpoints above md
            // Else use a Drawer
            <FlyoutTrigger id="cart-flyout">
              {flyout => (
                <Button
                  data-testid="header-cart"
                  onClick={
                    flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                  }
                  className="cart-button"
                  isActive={flyout.isOpen}
                  isEztrad={isEztrad}
                >
                  <Above breakpoint="lg">
                    {matches =>
                      matches ? (
                        <div
                          className="cart-button-inner"
                          data-testid="cart-button"
                        >
                          {t('Cart')}
                          {itemsInCart > 0 && <Badge text={itemsInCart} />}
                          <Cart className="cart-icon" />
                        </div>
                      ) : (
                        <div className="cart-button-inner">
                          <IconContainer>
                            {itemsInCart > 0 && <Badge text={itemsInCart} />}
                            <Cart className="cart-icon" />
                          </IconContainer>
                          <CartLabel>{t('Cart')}</CartLabel>
                        </div>
                      )
                    }
                  </Above>
                </Button>
              )}
            </FlyoutTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButton;
