import React from 'react';
import styled, { css } from 'react-emotion';

const List = styled('ul')`
  ${props =>
    props.left
      ? css`
          li.top-nav-list-item:first-of-type {
            padding-left: 0px;
          }
        `
      : css`
          justify-content: flex-end;
          li.top-nav-list-item:last-of-type {
            padding-right: 0px;
          }
        `};
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  display: flex;
  flex: 1;
  align-items: center;
  z-index: 999;
  height: 100%;

  li.top-nav-list-item {
    list-style: none;
    display: block;
    padding: 0 0.75rem;
    white-space: nowrap;
    height: 100%;
    display: flex;
    a {
      display: flex;
      align-items: center;
    }
    svg path {
      fill: ${({ theme }) => theme.colors.green};
    }
  }
  ${({ theme }) => theme.below.lg} {
    flex-grow: 0;

    li.top-nav-list-item > a {
      flex-direction: column;
      justify-content: center;
    }
  }

  ${({ theme }) => theme.below.sm} {
    li.top-nav-list-item {
      padding: 0 0.4rem;
    }
  }
`;

export default ({ children, ...rest }) => (
  <List {...rest}>
    {React.Children.map(
      children,
      item =>
        item && (
          <li className="top-nav-list-item" fontSize={1}>
            {item}
          </li>
        )
    )}
  </List>
);
