import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../MaxWidth';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import { Link } from 'react-router-dom';
import useShopId from '../../../utils/useShopId';
import logoSandstroms from '../../../svg/LogoSandstroms.svg';
import logoRagards from '../../../svg/LogoRagards.svg';
import { EztradStyling } from './EztradStyling';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import DynamicContent from '../../DynamicContent';
import pageQuery from '../../PageQuery.gql';
import { useQuery } from 'react-apollo';
import AnimatedLink from '../../ui/AnimatedLink';

const Wrapper = styled('footer')`
  padding-top: 2.875rem;
  padding-bottom: 5rem;
  background-color: ${({ theme }) => theme.colors.beige};
  border-top: 6px solid ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.almostBlack};

  .top-section {
    width: 610px;
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;

    img {
      height: 37px;
      margin-bottom: 1.375rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 180%;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  h2,
  .top-section a {
    font-weight: 500;
    font-size: 15px;
    line-height: 142.04%;
    letter-spacing: 0.05em;
    font-variant: small-caps;
    text-transform: lowercase;
    color: ${({ theme }) => theme.colors.green};
  }

  .bottom-section {
    display: flex;

    h2 {
      margin-bottom: 1.125rem;
    }

    .newsletter,
    .links {
      flex: 1;
    }

    .newsletter {
      width: 100%;
    }

    .links {
      display: flex;
      ul {
        flex: 1;

        li {
          margin-bottom: 0.625rem;
          :last-child {
            margin-bottom: 0;
          }
          a {
            font-size: 0.875rem;
            color: inherit;
            line-height: 180%;
            text-decoration: none;
            :hover {
              border-bottom: 1px solid
                ${({ theme }) => theme.colors.almostBlack};
            }
          }
        }
      }
    }
  }

  ${({ theme }) => theme.above.md} {
    .bottom-section {
      > section {
        :first-child {
          padding-right: 0.5rem;
        }
        :last-child {
          padding-left: 0.5rem;
        }
      }

      .links ul {
        :first-child {
          padding-right: 0.5rem;
        }
        :last-child {
          padding-left: 0.5rem;
        }
      }
    }
  }

  ${({ theme }) => theme.below.md} {
    padding-top: 2.25rem;
    padding-bottom: 3.875rem;

    .top-section {
      margin-bottom: 3.25rem;
    }

    .bottom-section {
      flex-direction: column;
      align-items: center;
      .links {
        flex-direction: column;
        ul:first-child {
          margin-bottom: 3.375rem;
        }
      }
      h2,
      li {
        text-align: center;
      }
    }
  }
`;

const FooterLink = ({ url, title }) =>
  url && title ? (
    <li>
      <Link to={url}>{title}</Link>
    </li>
  ) : null;

const Column = ({ title, children }) => (
  <ul>
    {title && (
      <li>
        <h2>{title}</h2>
      </li>
    )}
    {children}
  </ul>
);

const Footer = ({
  description,
  descriptionLinkTitle,
  descriptionLinkUrl,
  newsletterTitle,
  children
}) => {
  const isEztrad = useShopId() === 'eztrad';
  const logo = isEztrad
    ? 'https://www.ragards.se/admin/M1/stage/images/ragards_white.png'
    : logoSandstroms;
  const isLink = descriptionLinkTitle && descriptionLinkUrl;

  return (
    <Wrapper className={isEztrad ? EztradStyling : ''}>
      <MaxWidth>
        <section className="top-section">
          <img src={logo} alt="Logo" className={'logo'} />
          {description && <p>{description}</p>}
          {isLink && (
            <AnimatedLink to={descriptionLinkUrl}>
              {descriptionLinkTitle}
            </AnimatedLink>
          )}
        </section>
        <div className="bottom-section">
          <section className="newsletter">
            <NewsletterField newsletterTitle={newsletterTitle} />
          </section>
          <section className="links">{children}</section>
        </div>
      </MaxWidth>
    </Wrapper>
  );
};

const FooterWrapper = () => {
  const { pageIds } = useShopConfig();
  const { data, loading } = useQuery(pageQuery, {
    variables: { id: pageIds.footer }
  });

  if (loading) return null;

  return (
    <DynamicContent
      content={data.page.content}
      additionalComponents={{
        footer: Footer,
        column: Column,
        link: FooterLink
      }}
    />
  );
};

export default FooterWrapper;
