import React from 'react';
import styled from 'react-emotion';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 16px;
    height: 16px;
    border: 0;
    border-radius: 50%;
    font-size: 0.625rem;
    line-height: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <span>{text}</span>
    </Wrapper>
  );
};

export default Badge;
