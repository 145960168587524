import React from 'react';
import styled, { css } from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import { AddToCartButtonStyles } from '../../ui/Button';

const FieldErrorWrapper = styled('div')`
  ${({ theme }) => theme.below.md} {
    margin-bottom: 2.875rem;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 46px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  form,
  input {
    height: 100%;
    width: 100%;
  }

  form {
    display: flex;
    width: 360px;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    border: 1px solid #bfbdbd;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.colors.lightGreen};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.primary};
    :focus {
      outline: none;
    }
    ::placeholder {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.almostBlack};
      font-family: ${({ theme }) => theme.fonts.primary};
      opacity: 1;
    }
  }
  button {
    ${AddToCartButtonStyles};
    height: 100%;
    padding: 0 1.25rem;
    width: auto;
    flex-shrink: 0;
  }

  ${({ theme }) => theme.below.md} {
    justify-content: center;
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <FieldErrorWrapper>
              {this.state.error ? (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('You have already subscribed to the newsletter!')
                    : t(
                        'Something went wrong. Please check your email and try again.'
                      )}
                </Error>
              ) : (
                <label htmlFor="newsletter">
                  {this.props.newsletterTitle && (
                    <h2>{this.props.newsletterTitle}</h2>
                  )}
                </label>
              )}
              <Wrapper>
                {/* Show the completion success message once signed up, otherwise show the form */}
                {this.state.completed ? (
                  <p>{t('You are now subscribed')}</p>
                ) : (
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          name="newsletter"
                          type="email"
                          placeholder={t('Fill in your email address')}
                          onChange={this.updateEmail}
                        />
                      )}
                    </Intl>
                    <button
                      className={css`
                        opacity: ${this.state.email === '' ? 0.5 : 1};
                      `}
                    >
                      {/* Display 'Submitting…' while the form is posting */}
                      {result.loading ? t('Submitting…') : t('Send')}
                    </button>
                  </form>
                )}
              </Wrapper>
            </FieldErrorWrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
