import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import SubMenuWrapper from './SubMenuWrapper';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const Wrapper = styled('div')`
  height: 100%;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
  }
`;

const MainMenuItem = styled('li')`
  height: 100%;
  display: flex;
  padding: 0 0.75rem;
  border-color: ${({ theme }) => theme.colors.green};
  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    font-size: 0.9375rem;
    font-variant: small-caps;
    color: inherit;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-transform: lowercase;
    border-top: 4px solid transparent;
    border-bottom: 4px solid;
    border-bottom-color: ${({ isActive }) =>
      isActive ? 'inherit' : 'transparent'};

    &.active {
      color: inherit;
      border-bottom-color: inherit;
    }
  }
`;

const CategoryMenuContainer = ({ categories }) => {
  const { pageIds } = useShopConfig();
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {categories
              ? categories.map(cat => (
                  <MainMenuItem
                    key={cat.id}
                    // When mousing over a menu item, set it as the active nav
                    onMouseEnter={() => props.setActiveCategory(cat)}
                    isActive={props.activeCategories[0]?.id === cat.id}
                  >
                    <CategoryLink
                      // When following a category link, close the menu
                      onClick={props.clearActiveCategories}
                      category={cat}
                    >
                      {cat.name}
                    </CategoryLink>
                  </MainMenuItem>
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0 &&
              props.activeCategories[0].id !== pageIds.brands
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default CategoryMenuContainer;
