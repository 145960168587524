import EBGEot from './EBGaramond-Regular.eot';
import EBGWoff from './EBGaramond-Regular.woff';
import EBGWoff2 from './EBGaramond-Regular.woff2';
import EBGTtf from './EBGaramond-Regular.ttf';

import EBGSBEot from './EBGaramond-SemiBold.eot';
import EBGSBWoff from './EBGaramond-SemiBold.woff';
import EBGSBWoff2 from './EBGaramond-SemiBold.woff2';
import EBGSBTtf from './EBGaramond-SemiBold.ttf';

import MBEot from './Montserrat-Bold.eot';
import MBWoff from './Montserrat-Bold.woff';
import MBWoff2 from './Montserrat-Bold.woff2';
import MBTtf from './Montserrat-Bold.ttf';

import MMEot from './Montserrat-Medium.eot';
import MMWoff from './Montserrat-Medium.woff';
import MMWoff2 from './Montserrat-Medium.woff2';
import MMTtf from './Montserrat-Medium.ttf';

import MEot from './Montserrat-Regular.eot';
import MWoff from './Montserrat-Regular.woff';
import MWoff2 from './Montserrat-Regular.woff2';
import MTtf from './Montserrat-Regular.ttf';

import OEot from './OswaldBold.eot';
import OWoff from './OswaldBold.woff';
import OWoff2 from './OswaldBold.woff2';
import OTtf from './OswaldBold.ttf';

import { injectGlobal } from 'emotion';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return injectGlobal`
  /* Garamond Regular font */
  @font-face {
    font-family: 'EB Garamond';
    src: url(${EBGEot}); /* IE9 Compat Mode */
    src: local('EB Garamond Regular'), local('EBGaramond-Regular'),
        url(${EBGWoff2}) format('woff2'),
        url(${EBGWoff}) format('woff'),
        url(${EBGTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oswald';
    src: url(${OEot}); /* IE9 Compat Mode */
    src: local('Oswald Bold'), local('Oswald Bold'),
        url(${OWoff2}) format('woff2'),
        url(${OWoff}) format('woff'),
        url(${OTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* Garamond Semi-Bold font */
  @font-face {
    font-family: 'EB Garamond';
    src: url(${EBGSBEot}); /* IE9 Compat Mode */
    src: local('EB Garamond Semi-Bold'), local('EBGaramond-SemiBold'),
        url(${EBGSBWoff2}) format('woff2'),
        url(${EBGSBWoff}) format('woff'),
        url(${EBGSBTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Regular font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MEot}); /* IE9 Compat Mode */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(${MWoff2}) format('woff2'),
        url(${MWoff}) format('woff'),
        url(${MTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Medium font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MMEot}); /* IE9 Compat Mode */
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url(${MMWoff2}) format('woff2'),
        url(${MMWoff}) format('woff'),
        url(${MMTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Montserrat Bold font */
  @font-face {
    font-family: 'Montserrat';
    src: url(${MBEot}); /* IE9 Compat Mode */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url(${MBWoff2}) format('woff2'),
        url(${MBWoff}) format('woff'),
        url(${MBTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;
}
