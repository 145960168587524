import React from 'react';
import t from '@jetshop/intl';
import { css } from 'react-emotion';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { MenuButton, IconContainer } from './Header';

const containerStyling = css`
  svg {
    width: 18px;
    height: 18px;
    overflow: visible;
  }
`;

function SearchButton(props) {
  const { setSearchOpen } = props;
  return (
    <MenuButton
      className={containerStyling}
      onClick={() => setSearchOpen(true)}
    >
      <IconContainer>
        <Search className="search-icon" />
      </IconContainer>
      <label>{t('Search')}</label>
    </MenuButton>
  );
}

export default SearchButton;
