import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const useShopId = () => {
  const {
    apolloConfig: { shopid }
  } = useShopConfig();
  return shopid;
};

export default useShopId;
