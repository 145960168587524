import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import styled from 'react-emotion';
import { SearchField } from '@jetshop/ui/Search';
import AutocompleteQuery from './AutocompleteQuery.gql';
import Cross from '../../../svg/Cross.svg';
import { searchQuickResultsStyling } from './DesktopSearchField';
import { eztradQuickSearchStyling } from './EztradStyling';
import useShopId from '../../../utils/useShopId';

const PosedSearchBarContainer = styled(
  posed('div')({
    enter: {
      marginTop: 0,
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-30px',
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)`
  position: relative;
  width: 100%;
`;

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 4rem;
    top: 0;
    background-color: ${({ theme }) => theme.colors.green};
    svg {
      width: 18px;
      height: 18px;
      overflow: visible;
      path {
        fill: white;
      }
    }
  }
`;

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    display: flex;
    height: 50px;
    width: 100% !important;
    justify-content: center;
    input {
      border: 0;
      background: #f3f3f3;
      height: 100%;
      width: 100%;
      padding: 0 calc(1.125rem + 50px) 0 1.125rem;
      border: 1px solid ${({ theme }) => theme.colors.lightGreen};
      background-color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      font-size: 1rem;
      border-radius: 0;
      color: ${({ theme }) => theme.colors.green};
      :focus {
        outline: none;
      }
      ::-webkit-search-cancel-button {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        background-image: url(${Cross});
        background-size: contain;
      }
    }

    > div {
      top: 100% !important;
      border: none;
      width: calc(100% - 64px);

      > div {
        padding: 0 !important;
        ${searchQuickResultsStyling};
        ${({ isEztrad }) => isEztrad && eztradQuickSearchStyling};
      }

      ${({ theme }) => theme.below.md} {
        left: 0;
      }
    }
  }
  ${SearchField.Cancel} {
    height: 100%;
    min-width: 48px;
    width: fit-content;
  }
  ${SearchField.Flyout} {
    text-align: left;
    top: calc(100% + ((54px - 100%) / 2));
    ${({ theme }) => theme.below.md} {
      top: calc(100% + ((64px - 100%) / 2));
      width: calc(100% + 4rem + 16px);
      left: -8px;
      border-left: none;
      border-right: none;
    }
    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      padding: 0.5rem;
    }
    a {
      display: block;
    }
  }
`;

const SearchBar = ({ searchOpen, setSearchOpen }) => {
  const isEztrad = useShopId() === 'eztrad';
  return (
    <PoseGroup flipMove={true}>
      {searchOpen && (
        <PosedSearchBarContainer key={'searchBarPosed'}>
          <InnerSearchBar className="search-bar">
            <StyledSearchField
              isEztrad={isEztrad}
              className={'search-bar-input-field'}
              autocompleteQuery={AutocompleteQuery}
              onCancel={() => setSearchOpen(false)}
            />
            <div className="icon-container">
              <Search />
            </div>
          </InnerSearchBar>
        </PosedSearchBarContainer>
      )}
    </PoseGroup>
  );
};

export { SearchBar };
