import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import Button, { AddToCartButtonStyles, AddToCartButton } from '../ui/Button';
import { Link } from 'react-router-dom';
import AnimatedFlyoutTarget from './AnimatedFlyoutTarget';
import useShopId from '../../utils/useShopId';
import { eztradStyling } from './EztradStyling';

const containerStyling = css`
  background: ${theme.colors.white};
  padding: 1.5rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-weight: 500;
      font-size: 0.9375rem;
      letter-spacing: 0.05em;
      font-variant: small-caps;
      text-transform: lowercase;
      color: ${theme.colors.black};
    }
    button {
      cursor: pointer;
      background-color: transparent;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg path {
        stroke: ${theme.colors.black};
      }
    }
  }

  .body-container {
    .total-info-top {
      margin-top: 2.625rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      span.count {
        font-weight: 500;
        font-size: 0.75rem;
        color: ${theme.colors.grey};
      }
      .price {
        font-size: 0.875rem;
        font-weight: 700;
        color: ${theme.colors.almostBlack};
      }
    }

    .buttons-container {
      display: flex;
      margin: 0.75rem -0.5625rem 0;
      > a,
      > button {
        margin: 0 0.5625rem;
        height: 50px;
      }
    }

    .cart-items {
      margin-top: 2.8125rem;
      border-top: 1px solid ${theme.colors.mediumGrey};
    }

    .total-info-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      .label {
        font-weight: 500;
        font-size: 0.875rem;
        color: ${theme.colors.almostBlack};
      }
      .price {
        font-weight: 700;
        font-size: 1.125rem;
        color: ${theme.colors.almostBlack};
      }
    }

    .empty-cart-container p {
      padding: 100px 0;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: center;
      color: ${theme.colors.almostBlack};
    }
  }

  ${theme.below.md} {
    padding: 0;
    .header {
      padding: 0 1.125rem;
      border-bottom: 1px solid ${theme.colors.lightGreen};
      justify-content: center;
      height: 75px;
      button {
        position: absolute;
        right: 1.125rem;
      }
    }
    .body-container {
      padding: 0 1.125rem 1.125rem;

      .total-info-top {
        margin-top: 1.625rem;
      }

      .buttons-container {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        > a,
        > button {
          margin: 0 0 0.625rem;
        }
      }

      .cart-items {
        margin-top: 1.375rem;
      }
    }
  }
`;

const CheckoutButton = styled('a')`
  ${AddToCartButtonStyles};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ItemCount = ({ count }) => (
  <span className="count">
    {t(
      `{
        count, plural,
        =0 {0 item total}
        one {1 item total}
        other {{count} items total}
            }`,
      { count }
    )}
  </span>
);

const flyoutStyling = css`
  right: 0px;
  left: auto !important;
  width: 464px;
  position: absolute;
  top: calc(100% + 1px);
  max-height: calc(100vh - 81px);
  max-width: calc(100% - 52px);
  overflow: auto;
  z-index: 999;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), 2px 2px 32px rgba(0, 0, 0, 0.06);

  ${theme.below.md} {
    top: 0;
  }
`;

const flyoutAnimationValues = {
  enter: {
    right: '0px',
    transition: {
      duration: 200,
      ease: 'linear'
    }
  },
  exit: {
    right: '-464px',
    transition: {
      duration: 200,
      ease: 'linear'
    }
  }
};

const CartFlyoutView = ({ result, modal }) => {
  const items = result?.data?.cart?.items || [];
  const itemCount = result?.data?.cart?.totalQuantity || 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const price = result?.data?.cart?.productTotal || 0;
  const { hideTarget } = modal;
  const track = useTracker();

  return (
    <div className={cx(containerStyling, 'flyout-container')}>
      <div className="header">
        <h2>{t('Cart')}</h2>
        <button onClick={hideTarget}>
          <Cross />
        </button>
      </div>
      <div className="body-container">
        <div className="total-info-top">
          <ItemCount count={itemCount} />
          <Price price={price} />
        </div>
        <div className="buttons-container">
          {checkoutUrl ? (
            <CheckoutButton
              href={`${checkoutUrl}`}
              onClick={() => {
                track(trackCartCheckoutEvent({ cart: result.data.cart }));
              }}
            >
              {t('Check out')}
            </CheckoutButton>
          ) : (
            <AddToCartButton disabled>{t('Check out')}</AddToCartButton>
          )}
          <Button onClick={hideTarget}>{t('Continue shopping')}</Button>
        </div>
        {items.length !== 0 ? (
          <>
            <div className="cart-items">
              {items.map(item => (
                <CartItem item={item} key={item.id} />
              ))}
            </div>
            <div className="total-info-bottom">
              <span className="label">{t('Total')}</span>
              <Price price={price} />
            </div>
          </>
        ) : (
          <div className="empty-cart-container">
            <p>{t('Your cart is empty')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const CartFlyout = props => {
  const isEztrad = useShopId() === 'eztrad';

  return (
    // Wrap the flyout with the needed providers
    <CartProvider query={cartQuery}>
      {result =>
        result?.data?.cart && (
          <AnimatedFlyoutTarget
            id="cart-flyout"
            className={cx(flyoutStyling, isEztrad && eztradStyling)}
            animationValues={flyoutAnimationValues}
          >
            {flyout => (
              <CartFlyoutView modal={flyout} result={result} {...props} />
            )}
          </AnimatedFlyoutTarget>
        )
      }
    </CartProvider>
  );
};

export default CartFlyout;
