import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';

const shopId = process.env.REACT_APP_SHOP_ID || 'eztrad';
const isEztrad = shopId === 'eztrad';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'eztrad',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 },
      },
    ],
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {},
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  /** Old filter API will be removed in 5.0.0 */
  useNewFilterAPI: true,
  deprecations: {
    /** Will be removed in 6.0.0 */
    useOldProductVariants: false,
  },
  routes: { stores: { path: '/butiker' } }, // value is for Eztrad testing
  pageIds: {
    men: isEztrad ? 159 : 153,
    woman: isEztrad ? 162 : 154,
    brands: isEztrad ? 180 : 234,
    stores: isEztrad ? 173 : 232,
    support: isEztrad ? 175 : 233,
    footer: 52,
    header: 53,
  },
};
