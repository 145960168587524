import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import Cross from '../../../svg/Cross.svg';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { theme } from '../../Theme';
import { eztradQuickSearchStyling } from './EztradStyling';
import useShopId from '../../../utils/useShopId';

export const searchQuickResultsStyling = css`
  background-color: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.green};
  ul {
    border-left: 1px solid ${theme.colors.lightGrey};
    border-right: 1px solid ${theme.colors.lightGrey};
    li {
      border-bottom: 1px solid ${theme.colors.lightGrey};
      overflow: hidden;
      position: relative;
      white-space: normal;
      padding: 0;

      a {
        padding: 0.5rem 0.5rem;
        font-weight: 500;
        font-size: 0.8125rem;
        color: ${theme.colors.green};
        text-decoration: none;
        display: block !important;
        line-height: 125%;
      }
    }
  }

  ${theme.below.md} {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    ul li a {
      padding: 1rem 0.5rem;
      font-size: 1rem;
    }
  }
`;

const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const SearchFieldWrapper = styled('div')`
  width: 124px;
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGreen};
    color: inherit;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 500;
    font-size: 0.8125rem;
    letter-spacing: 0.01em;
    :focus {
      outline: none;
    }
    ::placeholder {
      opacity: 1;
    }
    ::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      background-image: url(${Cross});
      background-size: contain;
    }
  }

  svg {
    right: 0;
    height: 18px;
    width: 18px;
    overflow: visible;
    margin-left: 2px;
    path {
      fill: ${({ theme }) => theme.colors.green};
    }
  }
`;

const Flyout = styled('div')`
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 100%;
  left: 0;
`;

const SearchResults = ({ term, result, loading, onClick }) => {
  return result.length > 0 ? (
    <ul style={loading ? { opacity: 0.5 } : null}>
      {result.map(item => (
        <SuggestedTerm
          term={term}
          onClick={onClick}
          key={item.id}
          product={item}
        />
      ))}
    </ul>
  ) : (
    !loading && null
  );
};

const DesktopSearchField = ({
  initialValue,
  placeholder = '',
  autocompleteQuery,
  ...rest
}) => {
  const isEztrad = useShopId() === 'eztrad';
  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );

  return (
    <SearchAutoCompleteContainer
      autocompleteQuery={autocompleteQuery}
      initialValue={initialValue}
      focusOnLoad={true}
      {...rest}
    >
      {searchField => {
        const { inputRef } = searchField;
        return (
          <Wrapper className={'search-field'}>
            <SearchFieldWrapper>
              <FlyoutTrigger showCover={false} id="searchFlyout">
                {({ showTarget, hideTarget }) => (
                  <>
                    <input
                      placeholder={placeholder}
                      {...searchField.getInputProps({
                        onFocus: showTarget
                      })}
                    />
                  </>
                )}
              </FlyoutTrigger>

              {(!inputRef.current || !inputRef.current.value) && <Search />}

              <FlyoutTarget id="searchFlyout">
                {flyout =>
                  searchField.isDirty &&
                  !(searchField.loading && searchField.result.length === 0) &&
                  searchField.result.length > 0 &&
                  searchField.isOpen &&
                  flyout.isOpen && (
                    <Flyout>
                      <div
                        className={cx(
                          searchQuickResultsStyling,
                          isEztrad && eztradQuickSearchStyling
                        )}
                        {...searchField.getFlyoutProps()}
                      >
                        {searchField.isDirty && (
                          <>
                            <SearchResults
                              term={searchField.term}
                              result={searchField.result}
                              onClick={flyout.hideTarget}
                              loading={searchField.loading}
                            />
                          </>
                        )}
                      </div>
                    </Flyout>
                  )
                }
              </FlyoutTarget>
            </SearchFieldWrapper>
          </Wrapper>
        );
      }}
    </SearchAutoCompleteContainer>
  );
};

export default DesktopSearchField;
