import Accordion from '@jetshop/ui/Accordion/Accordion';
import Link from '@jetshop/ui/CategoryLink';
import { ReactComponent as Carrot } from '../../../../svg/Carrot.svg';
import React from 'react';
import styled, { css } from 'react-emotion';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const StyledCarrot = styled(Carrot)`
  margin-right: ${({ theme }) => theme.space[2]};
  transition: transform 200ms;
  ${({ open }) => open && 'transform: rotate(-180deg);'};
`;

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.white};

  flex: 1 0 auto;

  a,
  a:hover {
    text-decoration: none;
  }

  ${({ root, theme }) =>
    root &&
    css`
      border-top: 1px solid ${theme.colors.lightGreen};
    `};
`;
const SubCategories = styled(CategoryTree)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  li {
    padding-left: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
    :last-child {
      border-bottom: none;
    }
  }
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 18px 1em;
  font-size: 0.875rem;
  font-variant: normal;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  color: ${({ theme }) => theme.colors.green};

  ${StyledCarrot} {
    display: none;
    margin-right: 0px;
  }

  &.with-chevron {
    ${StyledCarrot} {
      display: block;
    }
  }

  position: relative;

  ul li &.active,
  ul li ul li &.active {
    font-weight: bold;
  }

  ul li ul li &,
  ul li:last-child ul li & {
    border-bottom: none;
  }

  /* ul li:last-child &, */
  ul li ul li:last-child & {
    border-bottom: none;
  }

  ul li ul {
    border-bottom: none;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

const CategoryName = styled('h2')`
  a {
    font-variant: small-caps;
    text-transform: lowercase;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.green};
  }
`;

const SubCategory = styled('li')`
  font-size: 0.875rem;
`;

const RootCategory = styled('li')`
  display: flex;
  flex-direction: column;
`;

const DrawerMenu = ({ closeMenu, categories }) => {
  const { pageIds } = useShopConfig();

  const renderSubCategories = categories => {
    if (!categories || !categories.length) return null;
    return (
      <SubCategories className="sub-categories">
        {categories.map(cat => {
          return (
            <SubCategory key={cat.id}>
              <CategoryLink category={cat} onClick={() => closeMenu()} />
            </SubCategory>
          );
        })}
      </SubCategories>
    );
  };

  const onClick = (e, hasChildren, index, handleAccordionClick) => {
    // If there are subcategories, prevent following the link
    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      closeMenu();
    }
    handleAccordionClick(index);
  };

  if (!categories || categories.length === 0) return null;

  return (
    <CategoryTree className="category-tree" root>
      <Accordion single>
        {({ openIndexes, handleClick, AccordionContent }) =>
          categories.map((cat, index) => {
            let classNames = [];
            // Give categories with children a chevron
            // Except it is the brands category
            let subCatsLength = cat.subcategories.length;
            if (pageIds.brands === cat.id) subCatsLength = 0;
            else subCatsLength && classNames.push('with-chevron');

            // Add 'open' class when accordion is opened
            const open = openIndexes.includes(index);
            open && classNames.push('open');

            return (
              <RootCategory key={cat.id}>
                <CategoryName>
                  <CategoryLink
                    category={cat}
                    onClick={e => onClick(e, subCatsLength, index, handleClick)}
                    className={classNames.length > 0 && classNames.join(' ')}
                  >
                    {cat.name}
                    <StyledCarrot open={open} />
                  </CategoryLink>
                </CategoryName>
                <AccordionContent isOpen={open}>
                  {renderSubCategories(cat.subcategories)}
                </AccordionContent>
              </RootCategory>
            );
          })
        }
      </Accordion>
    </CategoryTree>
  );
};

export default DrawerMenu;
