import { Link } from 'react-router-dom';
import styled, { css } from 'react-emotion';
import React from 'react';
import useShopId from '../../utils/useShopId';
import { theme } from '../Theme';

const styling = ({ isEztrad }) => css`
  position: relative;
  text-decoration: none;
  background-color: transparent;

  @keyframes linkAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  :hover,
  :focus {
    outline: none;
  }

  :after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    top: 100%;
    background-color: ${isEztrad
      ? theme.colors.almostBlack
      : theme.colors.green};
    width: 100%;
  }

  :hover :after {
    animation: linkAnimation 350ms;
  }
`;

const StyledLink = styled(Link, {
  shouldForwardProp: prop => ['isEztrad'].indexOf(prop) === -1
})`
  ${({ isEztrad }) => styling({ isEztrad })}
`;

const Button = styled('button')`
  ${({ isEztrad }) => styling({ isEztrad })}
`;

const ExternalLink = styled('a')`
  ${({ isEztrad }) => styling({ isEztrad })}
`;

const AnimatedLink = props => {
  const isEztrad = useShopId() === 'eztrad';
  return <StyledLink isEztrad={isEztrad} {...props} />;
};

export const AnimatedExternalLink = props => {
  const isEztrad = useShopId() === 'eztrad';
  return <ExternalLink isEztrad={isEztrad} {...props} />;
};

export const AnimatedButton = props => {
  const isEztrad = useShopId() === 'eztrad';
  return <Button isEztrad={isEztrad} {...props} />;
};

export default AnimatedLink;
