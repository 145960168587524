import React, { useMemo } from 'react';
import styled from 'react-emotion';
import MobileCategories from './Categories/MobileCategories';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import CloseButton from '../../ui/CloseButton';
import { Link } from 'react-router-dom';
import DynamicContent from '../../DynamicContent';

const NavBarHeader = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 80px;
  border-top: 6px solid ${({ theme }) => theme.colors.green};
  padding: 0 1rem;
  img {
    height: 28px;
  }
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.colors.beige};
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex: 0 0 auto;
`;

const SecondaryMenuItem = styled.li`
  list-style: none;
  display: block;
  padding: 1em 1em 0.9375em;
  a,
  a:active {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.grey};
    font-size: 0.875rem;
  }
  button {
    background: none;
    color: ${({ theme }) => theme.colors.grey};
    font-size: 0.875rem;
  }
`;

const MobileLink = props => {
  const { url, title, drawer } = props;
  return url && title ? (
    <SecondaryMenuItem>
      <Link to={url} onClick={drawer.hideTarget}>
        {title}
      </Link>
    </SecondaryMenuItem>
  ) : null;
};

const MobileMenuContent = ({ drawer, logo, categories, children }) => {
  const components = useMemo(() => {
    return {
      mobileLink: props => <MobileLink {...props} drawer={drawer} />
    };
  }, [drawer]);

  return (
    <>
      <NavBarHeader className="mobile-menu-header">
        <CloseButton onClick={drawer.hideTarget} />
        <img
          alt="Logo"
          src="https://www.ragards.se/admin/M1/stage/images/ragards_white.png"
        />
      </NavBarHeader>
      <Scroll className="mobile-menu-body">
        <MobileCategories
          categories={categories}
          closeMenu={drawer.hideTarget}
        />
        <SecondaryMenu className="mobile-secondary-menu">
          <DynamicContent
            content={children}
            additionalComponents={components}
          />
        </SecondaryMenu>
      </Scroll>
    </>
  );
};

export default function MobileMenu({ categories, ...props }) {
  if (!categories) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <MobileMenuContent
            categories={categories}
            drawer={drawer}
            {...props}
          />
        </Drawer>
      )}
    </DrawerTarget>
  );
}
