import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'react-emotion';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import MaxWidth from '../MaxWidth';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import SearchButton from './SearchButton';
import TopNav from './TopNav';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { ReactComponent as Stores } from '../../../svg/Stores.svg';
import useShopId from '../../../utils/useShopId';
import DesktopSearchField from './DesktopSearchField';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { Intl } from '@jetshop/intl';
import logoSandstroms from '../../../svg/LogoSandstroms.svg';
import logoRagards from '../../../svg/LogoRagards.svg';
import { EztradStyling, EztradLinkStyling } from './EztradStyling';
import CategoryMenuContainer from './CategoryMenu/CategoryMenuContainer';
import { SearchBar } from './SearchBar';
import { Logo } from './Logo';
import pageQuery from '../../PageQuery.gql';
import DynamicContent from '../../DynamicContent';

export const StyledLink = styled(Link, {
  shouldForwardProp: prop => ['isActive', 'isEztrad'].indexOf(prop) === -1
})`
  text-decoration: none;
  font-size: 0.9375rem;
  font-variant: small-caps;
  color: inherit;
  letter-spacing: 0.05em;
  font-weight: 500;
  text-transform: lowercase;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  ${({ theme }) => theme.above.md} {
    border-bottom-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.green : 'transparent'};

    :hover {
      border-bottom-color: ${({ theme }) => theme.colors.green};
    }
  }

  ${({ theme }) => theme.above.lg} {
    svg {
      margin-right: 7px;
    }
  }

  ${({ theme }) => theme.below.md} {
    font-size: 0.625rem;
  }

  ${({ isEztrad }) => isEztrad && EztradLinkStyling};
`;

const Container = styled('header')`
  background-color: ${({ theme }) => theme.colors.beige};
  position: relative;
  color: ${({ theme }) => theme.colors.green};

  label {
    text-decoration: none;
    font-size: 0.9375rem;
    font-variant: small-caps;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
  }
  ${({ theme }) => theme.below.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      font-size: 0.625rem;
    }
  }

  ${({ isEztrad }) => isEztrad && EztradStyling};
`;

const HeaderContainer = styled('div')`
  height: 81px;
  position: relative;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: solid 6px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.green};

  ${({ theme }) => theme.above.md} {
    > div {
      height: 100%;
    }
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .stores-link svg {
    height: 17px;
    width: 20px;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  position: static;
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  text-transform: lowercase;
  justify-content: center;
  svg {
    height: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 30px;
  margin: 0;
`;

const PageLink = ({ to, ...props }) => {
  const { pathname } = useLocation();
  const isActive = pathname === to || pathname.startsWith(to);
  const isEztrad = useShopId() === 'eztrad';
  return (
    <StyledLink isEztrad={isEztrad} isActive={isActive} to={to} {...props} />
  );
};

function TopHeader({ categories, supportPage, storesPage, logo }) {
  return (
    <HeaderContainer className="header-container">
      <StyledMaxWidth>
        <HeaderItemsContainer>
          <Above breakpoint="md">
            {matchMD =>
              matchMD ? (
                <Above breakpoint="lg">
                  {matchLG =>
                    matchLG ? (
                      <XLHeader
                        categories={categories}
                        supportPage={supportPage}
                        storesPage={storesPage}
                        logo={logo}
                      />
                    ) : (
                      <LGHeader
                        categories={categories}
                        storesPage={storesPage}
                        logo={logo}
                      />
                    )
                  }
                </Above>
              ) : (
                <MobileHeader logo={logo} storesPage={storesPage} />
              )
            }
          </Above>
          <CartFlyout />
        </HeaderItemsContainer>
      </StyledMaxWidth>
    </HeaderContainer>
  );
}

const XLHeader = ({ categories, storesPage, supportPage, logo }) => (
  <>
    <TopNav className="topnav" left>
      <CategoryMenuContainer categories={categories} />
      <Intl>
        {t => (
          <DesktopSearchField
            autocompleteQuery={AutocompleteQuery}
            onCancel={() => {}}
            focusOnLoad={false}
            placeholder={t('Search product')}
          />
        )}
      </Intl>
    </TopNav>

    <Logo logo={logo} />

    <TopNav className="topnav" right>
      {storesPage && (
        <PageLink to={storesPage.primaryRoute.path} className="stores-link">
          <IconContainer>
            <Stores />
          </IconContainer>
          {storesPage.name}
        </PageLink>
      )}
      {supportPage && (
        <PageLink to={supportPage.primaryRoute.path}>
          {supportPage.name}
        </PageLink>
      )}
      <CartButton />
    </TopNav>
  </>
);

const LGHeader = ({ categories, storesPage, logo }) => {
  const [searchOpen, setSearchOpen] = React.useState(false);
  return (
    <>
      <TopNav className="topnav" left>
        <CategoryMenuContainer categories={categories} />
      </TopNav>

      {!searchOpen ? (
        <Logo logo={logo} />
      ) : (
        <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      )}

      <TopNav className="topnav" right>
        {!searchOpen && (
          <SearchButton setSearchOpen={setSearchOpen} key="searchButtonPosed" />
        )}
        {storesPage && (
          <PageLink to={storesPage.primaryRoute.path} className="stores-link">
            <IconContainer>
              <Stores />
            </IconContainer>
            {storesPage.name}
          </PageLink>
        )}
        <CartButton />
      </TopNav>
    </>
  );
};

const MobileHeader = ({ logo, storesPage }) => {
  const [searchOpen, setSearchOpen] = React.useState(false);
  return !searchOpen ? (
    <>
      <TopNav left>
        <DrawerTrigger preventOverflow={true} id="menu-drawer">
          {drawer => (
            <MenuButton
              onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
            >
              <IconContainer>
                <Menu className="menu-icon" />
              </IconContainer>
              <label>{t('Menu')}</label>
            </MenuButton>
          )}
        </DrawerTrigger>
      </TopNav>

      <Logo logo={logo} />

      <TopNav right>
        <SearchButton setSearchOpen={setSearchOpen} key="searchButtonPosed" />
        {storesPage && (
          <PageLink to={storesPage.primaryRoute.path} className="stores-link">
            <IconContainer>
              <Stores />
            </IconContainer>
            {storesPage.name}
          </PageLink>
        )}
        <CartButton />
      </TopNav>
    </>
  ) : (
    <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
  );
};

const Header = ({ children }) => {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const {
    pageIds: { stores, support, men, woman, brands }
  } = useShopConfig();

  const isEztrad = useShopId() === 'eztrad';
  const logo = isEztrad ? logoRagards : logoSandstroms;

  const categoriesToShow = [men, woman, brands];
  const mainCategories = result?.data?.categories?.filter(category =>
    categoriesToShow.includes(category.id)
  );

  const supportPage = result?.data?.categories?.find(
    category => category.id === support
  );
  const storesPage = result?.data?.categories?.find(
    category => category.id === stores
  );

  return (
    <>
      <ChannelBanner />
      <Container isEztrad={isEztrad}>
        <TopHeader
          categories={mainCategories}
          supportPage={supportPage}
          storesPage={storesPage}
          logo={logo}
        />
        <Below breakpoint="md">
          <MobileMenu
            categories={mainCategories}
            logo={logo}
            children={children}
          />
        </Below>
      </Container>
    </>
  );
};

const HeaderWrapper = () => {
  const { pageIds } = useShopConfig();

  const { data, loading } = useQuery(pageQuery, {
    variables: {
      id: pageIds.header
    }
  });

  if (loading) return null;

  return (
    <DynamicContent
      content={data.page.content}
      additionalComponents={{
        header: Header
      }}
    />
  );
};

export default HeaderWrapper;
