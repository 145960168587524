import React from 'react';
import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import shopConfig from '../../shop.config';

const isEztrad = shopConfig.apolloConfig.shopid === 'eztrad';

const EztradSmallButtonActiveStyle = ({ theme }) => css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.apricot};
  border-color: ${theme.colors.apricot};
`;

const SandstromsSmallButtonActiveStyle = ({ theme }) => css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.green};
  border-color: ${theme.colors.green};
`;

const EztradButtonStyle = ({ theme }) => css`
  font-size: 1rem;
  font-weight: 700;
  border-radius: 100px;
  font-family: ${theme.fonts.secondary};
  text-transform: uppercase;
  color: ${theme.colors.almostBlack};

  :hover {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  }
`;

const SandstromsButtonStyle = ({ theme }) => css`
  border: 1px solid ${theme.colors.lightGreen};
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 500;
  color: ${theme.colors.green};

  :hover {
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};
    border-color: ${theme.colors.green};
  }
`;

const TrendButtonStyle = ({ secondary, theme }) => css`
  background-color: ${theme.colors.white};
  max-width: 100%;
  text-align: center;
  padding: 0 0.5rem;
  transition: 100ms;

  ${isEztrad ? EztradButtonStyle({ theme }) : SandstromsButtonStyle({ theme })};

  :disabled {
    opacity: 0.7;
  }

  ${secondary &&
    css`
      background-color: white;
      border: 1px solid ${theme.colors.darkgrey};
      color: ${theme.colors.darkgrey};
      circle.path {
        stroke: black;
      }
    `};
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const SmallButtonStyle = ({ theme, isActive, secondary }) => css`
  padding: 10px 16px !important;
  font-size: 0.875rem !important;
  ${TrendButtonStyle({ secondary, theme })};
  height: 30px;
  min-width: 30px;
  width: auto;
  border: 1px solid
    ${isEztrad ? theme.colors.lightGrey : theme.colors.lightGreen};

  ${isEztrad && `text-transform:uppercase`};

  ${isActive &&
    (isEztrad
      ? EztradSmallButtonActiveStyle({ theme })
      : SandstromsSmallButtonActiveStyle({ theme }))};

  :focus {
    outline: none;
  }

  :hover {
    ${isEztrad
      ? isActive
        ? EztradSmallButtonActiveStyle({ theme })
        : `background-color: ${theme.colors.lightGrey}; 
           border-color: ${theme.colors.lightGrey};
           color: ${theme.colors.black};`
      : SandstromsSmallButtonActiveStyle({ theme })};
  }
`;

const EztradAddToCartButton = ({ theme }) => css`
  font-size: 0.875rem;
  font-weight: 700;
  font-variant: normal;
  text-transform: uppercase;

  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  :hover {
    background-color: ${theme.colors.darkTeal};
  }
`;

const SandstromsAddToCartButton = ({ theme }) => css`
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  background: ${theme.colors.green};
  color: ${theme.colors.white};
  :hover {
    background-color: ${theme.colors.darkGreen};
  }
`;

export const AddToCartButtonStyles = ({ theme }) => css`
  max-width: 100% !important;
  width: 100%;
  height: 56px;
  padding: 0 0.5rem;
  text-transform: lowercase;
  font-variant: small-caps;
  text-align: center;
  transition: 150ms;

  ${isEztrad
    ? EztradAddToCartButton({ theme })
    : SandstromsAddToCartButton({ theme })};

  :disabled {
    opacity: 0.7;
  }
`;

export const AddToCartButton = styled(UIButton)`
  ${AddToCartButtonStyles};
`;

export const SmallButton = styled(UIButton)`
  ${SmallButtonStyle};
`;

export const TrendLink = styled(Link, {
  shouldForwardProp: prop => ['secondary'].indexOf(prop) === -1
})`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button {...props}>
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
