import React, { useMemo, useContext } from 'react';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import TargetWrapper from '@jetshop/ui/Modal/TargetWrapper';
import posed, { PoseGroup } from 'react-pose';
import styled from 'react-emotion';

const FlyoutTarget = ({ id, children, className, animationValues }) => {
  const { modalId, isOpen, hideTarget, modalType, props } = useContext(
    ModalContext
  );

  const PoseContainer = useMemo(() => {
    return styled(
      posed('div')({
        ...animationValues
      })
    )``;
  }, [animationValues]);

  return (
    <PoseGroup flipMove={true}>
      {isOpen && modalId === id && modalType === 'flyout' && (
        <PoseContainer
          key={`posed-${id}`}
          className={className}
          animationValues={animationValues}
        >
          <TargetWrapper
            isOpen={isOpen}
            modalType={modalType}
            hideTarget={hideTarget}
            preventOverflow={props.preventOverflow}
          >
            {children}
          </TargetWrapper>
        </PoseContainer>
      )}
    </PoseGroup>
  );
};

export default FlyoutTarget;
