import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import t from '@jetshop/intl';

const SvgButtonWrapper = styled('button')`
  padding: 16px 34px 8px 0;
  background: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 14px;
    width: 14px;
  }
  span {
    color: ${({ theme }) => theme.colors.green};
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.2px;
    font-variant: small-caps;
    text-transform: uppercase;
    margin-top: 8px;
  }
`;

const CloseButton = ({ onClick }) => (
  <SvgButtonWrapper className="close-button-container" onClick={onClick}>
    <Cross />
    <span>{t('Close')}</span>
  </SvgButtonWrapper>
);

export default CloseButton;
