import React from 'react';
import styled from 'react-emotion';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.75rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 94rem;
  ${({ theme }) => theme.above.sm} {
    padding: 0 2rem;
  }
`;

const MaxWidth = ({ className = '', style = {}, children }) => {
  return (
    <Wrapper className={className} style={style}>
      {children}
    </Wrapper>
  );
};

export default MaxWidth;
