import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';
import sanitizeCss from '@jetshop/ui/Theme/sanitizeCss';

import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';
import shopConfig from '../shop.config';

/** 
    To define your own breakpoints, you can edit the breakpoint array below.
    The index of the breakpoint corresponds to the label. So, in this case, xs is at 20rem, sm is at 40rem, ect.
    You can add additional breakpoints or change the values of the ones that are currently there however it is important you keep the four existing labels as we use them for some components in @jetshop/ui 
. */

export const labels = ['xs', 'sm', 'md', 'lg', 'xl'];
const breakpoints = ['20rem', '40rem', '50rem', '64rem', '94rem'];

const { breakpointMap, above, below, between, only } = createBreakpointHelpers(
  labels,
  breakpoints
);

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '72px'
];
const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];
const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 700
};
let colors = {
  white: '#FFFFFF',
  black: '#000005',
  almostBlack: '#636363',
  mediumGrey: '#C5C5C5',
  lightGrey: '#F1F1F1',
  lightestGrey: '#FAFAFA',

  // Theme 1
  beige: '#FCFCF0',
  darkBeige: '#F3F2EB',
  green: '#006A44',
  darkGreen: '#003D27',
  lightGreen: '#ADCDC2',

  // Theme 2
  teal: '#472BF5',
  darkTeal: '#305B64',
  grey: '#7F7F7F',
  darkGrey: '#696969',
  darkestGrey: '#343434',
  softGrey: '#E8E8E8',

  apricot: '#FCD1AF',
  blue: '#472BF5',
  pink: '#FF6978',

  // Status colors
  statusGreen: '#2A891A',
  statusRed: '#E10E00',
  statusYellow: '#F9D410',

  // Discount Color
  discountRed: '#FF6978'
};

colors = {
  ...colors,
  highlight: colors.blue
};

const isEztrad = shopConfig.apolloConfig.shopid === 'eztrad';

export const fonts = isEztrad
  ? {
      primary: 'Montserrat',
      secondary: 'Oswald'
    }
  : {
      primary: 'Montserrat',
      secondary: 'EB Garamond'
    };

// Use https://meowni.ca/font-style-matcher/ to determine fallback styles that
// most closely match your fontFamily above
const fallbackStyles = isEztrad
  ? `
  font-family: Arial
  font-size: 16px
  font-weight: 400;
  line-height: 1.6
  word-spacing: 0.35px
  letter-spacing: 0.25px
  visibility: visible;
`
  : `
  font-family: Arial
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6
  word-spacing: 1.15px
  letter-spacing: 0.85px
  visibility: visible;
`;

// This function will inject a global sanitize.css into your app.
// sanitize.css is a CSS library that provides consistent, cross-browser default styling of HTML elements alongside useful defaults.
// normalize.css and reset.css are also options if you so choose and can be imported from the same directory as sanitize css
sanitizeCss();

injectGlobal`
  html, body {
    height: 100%;
    font-family: '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'Arial',
        'sans-serif'
  }
  html, body, #root {
    min-height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  button {
	  cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
	}
  ol, ul, h4, h3, h2, h1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p, dd {
    margin: 0;
    padding: 0;
  }
  input {
    -webkit-appearance: none;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }
  body {
    ${fallbackStyles};
    -webkit-font-smoothing: antialiased;
  }
  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  .graphic-element {
    width: 250%;
    height: auto;
    display: block;
    position: absolute;
    bottom: -80px;
    right: 0;
    z-index: -1;
    transform: translateX(25%);
  }
`;

export const theme = {
  labels,
  breakpoints,
  breakpointMap,
  above,
  below,
  between,
  only,
  fontSizes,
  space,
  fontWeights,
  colors,
  fonts
};

export default props => <ThemeProvider {...{ ...props, theme }} />;
