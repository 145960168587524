import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'react-emotion';

const Image = styled('img')`
  height: 43px;
  max-width: 100%;

  ${({ theme }) => theme.below.md} {
    height: 28px;
    padding: 0 10px;
  }
`;

// <Image className="logo" alt="Logo" src={logo} />

const Logo = ({ logo }) => (
  <Link to="/">
    <Image
      className="logo"
      alt="Logo"
      src="https://www.ragards.se/admin/M1/stage/images/ragards_white.png"
    />
  </Link>
);

export { Logo };
