import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import MaxWidth from '../../MaxWidth';

// Limit the menu to our global MaxWidth
const List = styled('ul')`
  display: block;
  column-count: 3;
  width: 636px;
  max-width: 100%;
  li {
    margin-bottom: 1.4375rem;
    a {
      font-size: 0.875rem;
      text-decoration: none;
      text-transform: none;
      color: ${({ theme }) => theme.colors.green};
      font-weight: 500;
    }
  }
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
  padding: 2.3125rem 0 3.25rem;
`;

const StyledMaxWidth = styled(MaxWidth)`
  position: static;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.green};
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  z-index: 10;
  height: 0;

  h2 {
    margin-bottom: 0;
    font-weight: 700;
    padding: 0;
  }

  ul li a {
    :hover {
      text-decoration: underline;
    }
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <PosedWrapper pose={pose} className="submenu-container">
      <StyledMaxWidth>
        <InnerWrapper>
          <List>
            {activeCategory &&
              activeCategory.subcategories?.map(cat => (
                <li key={cat.id}>
                  <h2>
                    <CategoryLink category={cat} onClick={closeNav}>
                      {cat.name}
                    </CategoryLink>
                  </h2>
                </li>
              ))}
          </List>
        </InnerWrapper>
      </StyledMaxWidth>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
